<template>
  <div class="main">
    <div class="topOne">
      <div class="top">
        <div class="top_01">
          <div class="top_011">
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">采样机构:</span>
              <el-select clearable value-key="orgCode" multiple collapse-tags v-model="selectArr" placeholder="请选择"
                size="medium">
                <el-option v-for="item in totalOrgList" :key="item.orgCode" :label="item.orgName" :value="item.orgCode">
                </el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">状态:</span>
              <el-select clearable class="select-width" size="medium" v-model="queryForm.status" placeholder="状态">
                <el-option v-for="item in stauteList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">结果:</span>
              <el-select clearable class="select-width" size="medium" v-model="queryForm.reportResult" placeholder="结果">
                <el-option v-for="item in resoutList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">人员信息:</span>
              <el-input clearable class="select-width id-code-width" size="medium" v-model="queryForm.personInfo"
                placeholder="姓名、电话、证件号码、条形码" />
            </div>
          </div>
          <div class="top_01_btns">
            <el-button type="primary" size="medium" @click="queryInfo" :loading="loading"
              style="margin-left:20px;">查询</el-button>
          </div>
        </div>
        <div class="top_02">
          <div class="top_011">
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">上报状态:</span>
              <el-select size="medium" clearable class="select-width" v-model="queryForm.pushFlag" placeholder="上报状态"
                style="width: 200px;">
                <el-option v-for="item in shangbaoList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">检测时间:</span>
              <el-date-picker unlink-panels v-model="pickerTime1" size="medium" type="datetimerange"
                value-format="yyyy-MM-dd HH:mm" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm" />
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">检测人员:</span>
              <el-select clearable filterable class="select-width" size="medium" v-model="queryForm.detectionUserId"
                placeholder="检测人员" style="width: 200px;">
                <el-option v-for="item in checkAcidListPeopleList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </div>
          </div>
        </div>
        <div class="top_02">
          <div class="top_011">
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">报告时间:</span>
              <el-date-picker unlink-panels style="width:320px;" v-model="pickerTime2" size="medium"
                type="datetimerange" value-format="yyyy-MM-dd HH:mm" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" format="yyyy-MM-dd HH:mm" />
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">顺序:</span>

              <el-tooltip class="item" effect="dark" content="年月日+序号，示例: 20210805000001" placement="top">
                <el-input-number size="medium" class="numInput" v-model="detectionCountStart" controls-position="right"
                  :min="1"></el-input-number>
              </el-tooltip>&nbsp;-&nbsp;
              <el-tooltip class="item" effect="dark" content="年月日+序号，示例: 20210805000001" placement="top">
                <el-input-number size="medium" class="numInput" v-model="detectionCountEnd"
                  controls-position="right"></el-input-number></el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div>
        <el-checkbox @change="changBox" v-model="checked" style="margin-left:10px;"></el-checkbox>
        <span style="cursor:pointer;margin-left:20px;">选中所有已检测</span>
        <el-button v-btn-permission="'008013002001'" type="primary" size="medium" @click="exportListData"
          :loading="exportLoading" style="margin-left:20px;">导出</el-button>
        <el-button v-btn-permission="'008013002002'" :disabled="checkData.length == 0 && checked == false"
          style="margin-left:20px;" type="primary" size="medium" @click="manyLog">批量录入结果</el-button>
      </div>
    </div>

    <div class="centerTwo">
      <el-table ref="multipleTable" :is-show-select="true" :loading="loading" :data="staffCheckList" table-size="medium"
        @selection-change="handleSelection">
        <el-table-column prop="transportStatus" type="selection" :selectable="selectable" width="55">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-link v-btn-permission="'008013002003'" v-if="row.statusName === '已检测'" type="primary"
              @click="enterCheckRecord(row)">录入</el-link>
            <el-link v-btn-permission="'008013002004'" v-if="row.statusName === '已报告'" type="primary"
              @click="checkRecord(row.id)">重传</el-link>
            <!-- <el-link type="primary" @click="checkRecord(row.id)">重传</el-link> -->
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="100" />
        <el-table-column prop="sex" label="性别" width="80" />
        <el-table-column prop="age" label="年龄" width="80" />
        <el-table-column prop="cardTypeName" label="证件类型" width="120" />
        <el-table-column prop="cardNo" label="证件号码" width="200" />
        <el-table-column prop="linkPhone" label="电话" width="120" />
        <el-table-column prop="statusName" label="状态">
        </el-table-column>
        <el-table-column prop="reportResult" label="结果" />
        <el-table-column prop="businessTypeName" label="业务类型" width="200" />
        <el-table-column prop="samplingMode" label="采样方式" width="200" />
        <el-table-column prop="samplingOrgName" label="采样机构" width="200" />
        <el-table-column prop="samplingTime" label="采样时间" width="200" />
        <el-table-column prop="detectionTime" label="检测时间" width="200" />
        <el-table-column prop="detectionName" label="检验登记人" width="200" />
        <el-table-column prop="reportTime" label="报告时间" width="200" />
        <el-table-column prop="reportName" label="报告登记人" width="200" />
        <el-table-column prop="detectionCount" label="检测顺序" width="200" />
        <el-table-column prop="pushFlagName" label="上报状态" width="200" />
        <el-table-column prop="pushFailureReason" label="失败原因" width="200" />
      </el-table>
    </div>

    <div class="bottomThree">
      <div class="content_page">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
          :page-size="pageSize" :total="total" :page-sizes="[100, 200, 300, 400]"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>

    <!--录入弹窗-->
    <EnterCheckRecord ref="EnterCheckRecordRef"></EnterCheckRecord>
    <!-- 批量录入弹窗 -->
    <piliangLuru ref="piliangLuruRef"></piliangLuru>

    <!-- //页面的遮罩层 -->
    <div id="cover" ref="cover">
      <div id="modal">
        <span>所在部门不是检测机构，无权限使用</span>
      </div>
    </div>
  </div>
</template>
  
<script>
let timeNow;
import TableModel from '@/components/TableModel';
import Dialog from '@/components/Dialog';
import EnterCheckRecord from './EnterCheckRecord';
import piliangLuru from './piliangLuru';
import {
  checkAcidList, //检查列表
  exportCheckAcidList, //导出检查列表
  reUpload, //重新上传
} from '../../api/HotelManagement/roomManagement';
import { getBusinessOrgList, getUserListByPeopleTypeApi } from '../../api/SystemManagement/index.js'
import { messageBox, importFile } from '../../utils/utils';
import moment from "moment"

export default {
  name: "StaffCheck",
  components: { TableModel, Dialog, EnterCheckRecord, piliangLuru },
  data() {
    return {
      isAcidOrg: JSON.parse(localStorage.getItem("orgInfo")).nucleicAcid,
      exportLoading: false,
      checkAcidListPeopleList: [],//检测人员列表
      totalOrgList: [],//居家隔离业务、集中隔离业务机构
      selectArr: [], //采样机构编码
      checked: false,
      props: { multiple: true },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      pageNumber: 1,
      pageSize: 100,
      total: 0,
      label: '',
      stauteList: [
        {
          value: '2',
          label: '已检测'
        },
        {
          value: '3',
          label: '已报告'
        },
      ],
      resoutList: [
        {
          value: '阴性',
          label: '阴性'
        },
        {
          value: '阳性',
          label: '阳性'
        },
        {
          value: '可疑',
          label: '可疑'
        }
      ],
      shangbaoList: [
        {
          value: '0',
          label: '未上报'
        },
        {
          value: '1',
          label: '已上报'
        },
      ],
      detectionCountStart: undefined, //顺序开始
      detectionCountEnd: undefined, //顺序结束
      queryForm: {
        status: "2", //状态
        reportResult: "", // 结果
        personInfo: "", //人员信息
        pushFlag: "", //上报状态
        detectionUserId: JSON.parse(localStorage.getItem("userdoctor")).id, //检测人员
      },
      loading: false,
      staffCheckList: [],
      pickerTime1: [],
      pickerTime2: [],
      checkData: [],
    }
  },
  created() {
    timeNow = moment().format('YYYY-MM-DD');
    this.pickerTime1[0] = `${timeNow} 00:00`
    this.pickerTime1[1] = `${timeNow} 23:59`
    this.orgList();
    this.peopleList();
    this.checkList();
  },
  mounted() {
    if (this.isAcidOrg == 0) {
      this.$refs.cover.style.display = "flex";   //显示遮罩层
    }
  },
  methods: {
    selectable(row, index) {
      if (row.statusName == '已报告') {
        return false
      }else{
        return true
      };
    },

    // 选中全选checkbox
    changBox() {
      if (this.checked == true) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 获取当前机构检测人员列表
    async peopleList() {
      let params = { orgCode: this.userdoctor.orgCode }
      let { data } = await getUserListByPeopleTypeApi({ params });
      this.checkAcidListPeopleList = data.data;
    },
    // 居家隔离业务、集中隔离业务机构机构
    async orgList() {
      let params = { homeIsolation: 1, centralizedIsolation: 1 }
      let { data } = await getBusinessOrgList({ params });
      this.totalOrgList = data.data;
    },
    // 获取表格检查列表
    async checkList() {
      this.loading = true;
      let params = this.setParamsInfo();
      // console.log(params, '打印参数')
      let { data } = await checkAcidList(params);
      if (data.code === '200') {
        this.staffCheckList = data.data.list
        this.total = data.data.totalRow
        this.loading = false;
      } else {
        this.$message.error(data.msg);
        this.loading = false;
      }
    },

    // 批量勾选
    handleSelection(val) {
      this.checkData = val;
    },
    // 批量录入
    manyLog() {
      let params = this.setParamsInfo();
      this.$refs.piliangLuruRef.show(this.checkData, params)
    },
    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      // 采样机构
      if (this.selectArr.length > 0) {
        params.samplingOrgCodes = this.selectArr;
      }
      let { status, reportResult, personInfo, pushFlag, detectionUserId } = this.queryForm;
      if (status) params.status = status; //状态
      if (reportResult) params.reportResult = reportResult; //结果
      if (personInfo) params.personInfo = personInfo; //人员信息
      if (pushFlag) params.pushFlag = pushFlag; //上报状态
      if (detectionUserId) params.detectionUserId = detectionUserId; //检测人员
      if (this.pickerTime1 && this.pickerTime1.length == 2) {
        // console.log(this.pickerTime1,'打印时间')
        params.detectionStartTime = this.pickerTime1[0] + ":00";
        params.detectionEndTime = this.pickerTime1[1] + ":59";
      }
      if (this.pickerTime2 && this.pickerTime2.length == 2) {
        // console.log(this.pickerTime1,'打印时间')
        params.reportStartTime = this.pickerTime2[0] + ":00";
        params.reportEndTime = this.pickerTime2[1] + ":59";
      }
      if (this.detectionCountStart) params.detectionCountStart = this.detectionCountStart; //顺序开始
      if (this.detectionCountEnd) params.detectionCountEnd = this.detectionCountEnd; //顺序结束
      return params;
    },

    // 导出
    exportListData() {
      let params = this.setParamsInfo();
      messageBox(() => {
        this.exportLoading = true;
        exportCheckAcidList(params).then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
          }
          importFile(res.data, '工作人员检测数据')
        })
      }, 'warning', '确定需要导出检测列表数据吗？')
    },
    //  查询
    queryInfo() {
      this.loading = true;
      this.pageNumber = 1;
      this.checkList();
    },

    // 清空查询参数
    resetQueryInfo() {
      this.pickerTime1 = [];
      this.pickerTime2 = [];
      this.queryForm = {
        status: "2", //状态
        reportResult: "", // 结果
        personInfo: "", //人员信息
        pushFlag: "", //上报状态
        detectionUserId: this.userdoctor.id, //检测人员
      };
      this.selectArr = [];
      this.detectionCountStart = undefined;
      this.detectionCountEnd = undefined;
      this.checkList();
    },
    // 重传
    checkRecord(idd) {
      let id = idd;
      this.$confirm('您确定要重传吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // let result = await reUpload(id);
        reUpload(id).then(res => {
          if (res.data.code === '200') {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消!'
        });
      });
    },
    // 录入
    enterCheckRecord(row) {
      this.$refs.EnterCheckRecordRef.show(row);
      this.$refs.EnterCheckRecordRef.resetFormInfo;
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.checkList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.checkList();
    },

  }
}
</script>
  
<style  scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.form-width {
  width: 280px;
}
</style>
  
<style lang="scss" scoped>
.el-table>>>td {
  padding: 6px 0 !important;
}

.el-link {
  margin-right: 10px;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: end;
}

.statusBtn {
  cursor: auto;
}

.statusBtn2 {
  cursor: auto;
}

.statusBtn:hover {
  background: rgb(64, 158, 255);
}

.statusBtn2:hover {
  background: rgb(245, 108, 108);
}

.tishiBox {
  width: 260px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  border: 1px solid black;
  display: none;
}

.numInput:hover .tishiBox {
  display: block !important;
  /* color: pink; */
}

.top {
  width: 100%;
}

.top_01 {
  display: flex;
  justify-content: space-between;
}

.top_011 {
  display: flex;
}

.top_01_div {
  display: flex;
  align-items: center;
}

.top_01_div span {
  width: 110px;
  text-align: right;
  margin-right: 10px;
}

.top_02 {
  margin-top: 20px;
}

.el-select {
  /deep/.el-select__tags {
    // 解决多个tag卡片溢出隐藏问题
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    .el-tag {
      // 注意这个inline-block 对卡片溢出隐藏很重要
      display: inline-block;

      // 解决单个tag文字过长溢出隐藏问题
      .el-select__tags-text {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .el-icon-close {
        top: -6px;
        right: -4px;
      }
    }
  }
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // border: 1px solid red;
}

#cover {
  position: absolute;
  right: 0px;
  left: 0;
  top: 0px;
  bottom: 0;
  background: rgba(203, 200, 200, 0.4);
  height: 100%;
  filter: alpha(opacity=60);
  // opacity: 0.6;
  // display: none;
  z-Index: 1;
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
}

#modal {
  color: rgb(91, 91, 91);
  font-weight: bold;
  cursor: pointer;
  font-size: 25px;
}
</style>
  