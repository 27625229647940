<template>
  <div>
    <el-dialog title="录入结果" :visible="isShow" v-if="isShow" width="50%" :before-close="closeDialog">
      <el-form :model="redformhs" ref="dialogForm" :rules="dialogFormRules">
        <el-form-item label-width="130px" label="项目名称：" prop="programName">
          <el-input disabled v-model="redformhs.programName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label-width="130px" label="报告结果：" prop="result">
          <el-radio-group v-model="redformhs.result">
            <el-radio label="阴性">阴性</el-radio>
            <el-radio label="阳性">阳性</el-radio>
            <el-radio label="可疑">可疑</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="130px" label="检验单位：" prop="detectionUnit">
          <el-input disabled v-model="redformhs.detectionUnit" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label-width="130px" label="检验医生：">
          <el-input v-model="redformhs.detectionDoctor" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label-width="130px" label="审核医生：">
          <el-input v-model="redformhs.auditDoctor" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label-width="130px" label="报告时间：" prop="reportTime">
          <el-date-picker class="top_left_checktime" type="datetime" v-model="redformhs.reportTime"
            format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submitFormInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
import { batchEnterResults ,queryEnterResults} from '../../api/HotelManagement/roomManagement';
import { configKey } from '../../api/SystemManagement/isolationpointmanagement'
export default {
  name: "EnterCheckRecord",
  components: { Dialog },
  data() {
    return {
      queryParams: {},
      loading: false,
      isShow: false,
      staffInfo: {},
      ids: [],
      redformhs: {
        programName: "", //项目名称
        result: "阴性",//报告结果
        detectionUnit: JSON.parse(localStorage.getItem("orgInfo")).orgName, //检验单位
        detectionDoctor: "",//检验医生
        auditDoctor: "",//审核医生
        reportTime: new Date() //报告时间
      },
      dialogFormRules: {
        programName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        result: [
          { required: true, message: "请选择报告结果", trigger: "change" },
        ],
        detectionUnit: [
          { required: true, message: "请输入检验单位", trigger: "blur" },
        ],
        reportTime: [
          { required: true, message: "请输入报告时间", trigger: "change" },
        ]
      },
    }
  },
  methods: {
    async show(data, params) {
      this.resetFormInfo();
      this.isShow = true;
      this.queryParams = params;
      // this.staffInfo = data;
      this.ids = data.map((item)=>{
        return item.id
      });
      let configkey = 'covid_check_project_name';
      let result = await configKey(configkey);
      this.redformhs = {
        programName: result.data.data, //项目名称（后台配置）
        result: "阴性",//报告结果
        detectionUnit: JSON.parse(localStorage.getItem("orgInfo")).orgName, //检验单位（有默认值）
        detectionDoctor: "",//检验医生
        auditDoctor: "",//审核医生
        reportTime: new Date() //报告时间
      }
    },
    // 清空表单内容
    resetFormInfo() {
      this.redformhs = {
        programName: "", //项目名称（有默认值）
        result: "阴性",//报告结果
        detectionUnit: JSON.parse(localStorage.getItem("orgInfo")).orgName, //检验单位（有默认值）
        detectionDoctor: "",//检验医生
        auditDoctor: "",//审核医生
        reportTime: new Date() //报告时间
      }
    },
    closeDialog() {
      this.isShow = false;
      this.$parent.queryInfo();
    },
    async submitFormInfo() {
      this.$refs['dialogForm'].validate(async (valid) => {
        if (valid) {
          let params = {};
          if (this.ids.length > 0) {
            params.ids = [];
            params.ids = this.ids
          }else{
            params.queryReq = this.queryParams
          }
          if (this.redformhs.result) params.result = this.redformhs.result;
          if (this.redformhs.detectionDoctor) params.detectionDoctor = this.redformhs.detectionDoctor;
          if (this.redformhs.auditDoctor) params.auditDoctor = this.redformhs.auditDoctor;
          if (this.redformhs.reportTime) params.reportTime = this.$moment(this.redformhs.reportTime).format("YYYY-MM-DD HH:mm:ss");;
          console.log(params, '打印参数')
          // return
          this.loading = true;
          let result;
          if(this.ids.length>0){
             result = await batchEnterResults(params);
          }else{
             result = await queryEnterResults(params);
          }
          if (result.status == 200) {
            this.loading = false;
          }
          let { code, msg } = result.data;
          if (code == 200) {
            this.$message.success(msg);
            this.isShow = false;
            // this.$refs.diglogRef.closeDialog();
            this.$parent.queryInfo();
            this.resetFormInfo();
          } else {
            this.$message.error(msg);
          }
        }
      })

    },
  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/enter-dialog.scss";
</style>
